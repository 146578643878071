import Vue from "vue";
import i18n from "@/i18n";
import CommonUtility from "@/util/CommonUtility.js";
Vue.filter("Translate", (value) => i18n.t(value));
Vue.filter("getText", CommonUtility.getText);

import dayjs from 'dayjs'
var utc = require('dayjs/plugin/utc')
var timezone = require('dayjs/plugin/timezone') // dependent on utc
dayjs.extend(utc)
dayjs.extend(timezone)

const dateFormatDash = (date, format = 'YYYY-MM-DD HH:mm:ss') => {
    return date ? dayjs(date).format(format) : '';
}

const dateFormatDashTimezone = (date, format = 'YYYY-MM-DD HH:mm:ss') => {
    if (date) {
        const utcDate = dayjs(date).utcOffset(16 * 60); // 添加8小时的偏移量
        return utcDate.format(format);
    }
    return date;
};

const dateOnly = (date, format = 'YYYY-MM-DD') => {
    return date ? dayjs(date).format(format) : '';
}
Vue.filter("dateFormatDash", dateFormatDash);
Vue.filter("dateOnly", dateOnly);
Vue.filter("dateFormatDashTimezone", dateFormatDashTimezone);