import axios from 'axios';
import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
Vue.use(Vuex);


function mapGroupOptions(data) {
  function mapOptions(item) {
    return {
      id: item.id,
      label: item.name,
      children: mapGroupOptions(item.children),
      parent_id: item.parent_id,
      display:item.display,
    };
  }

  return data.map(mapOptions);
}
function getGroupOptions(data) {
  let options = {};
  const mapOptions = (group_options) => {
    group_options.forEach((item) => {
      options[item.id] = item.label;
      if (item.children) {
        mapOptions(item.children);
      }
    });
  };
  mapOptions(data);
  return options;
};

export default new Vuex.Store({
  plugins: [createPersistedState()],
  state: {
    token: null,
    token_type: null,
    is_superuser: false,
    user_id: null,
    last_name: null,
    first_name: null,
    permissions: [],
    district_map: {},
    region_map: {},
    capability: 1000,
    total_capability: 10000,
    roles: [],
    group_options: [],
    group_ids_map: {}
  },
  getters: {
    getToken(state) {
      if (state.token_type != null) {
        return state.token_type + " " + state.token;
      }
      return null;
    },
    getCapability(state) {
      return state.capability;
    },
    getRoles(state) {
      console.log("getRoles getter");
      let temp = [];
      state.roles.forEach((role) => {
        if (role.value == "admin") return;
        temp.push(role);
      });
      return temp;
    },
    isSuperUser(state) {
      const super_permission = state.permissions.find(
        (item) => item[1] === "/api/v1/*" && item[2] === "*"
      );
      return state.is_superuser || (super_permission !== null && super_permission !== undefined);

    },
    isAllowPermission: (state, getters) => (object, action) => {
      // check if super user with getter
      let is_root = getters.isSuperUser;
      if (is_root) {
        return true;
      }
      // object ='/api/v1/* action *
      const permission = state.permissions.find(
        (item) => item[1] === object && item[2] === action
      );

      return permission !== null && permission !== undefined;
    },
    // permissions for case
    isAllowAddCase: (state, getters) => {
      return getters.isAllowPermission("/api/v1/cases/", "POST");
    },
    isAllowEditCase: (state, getters) => {
      return getters.isAllowPermission("/api/v1/cases/{case_id}", "PUT");
    },
    isAllowViewTransactionCase: (state, getters) => {
      // /case-transactions
      return getters.isAllowPermission("/case-transactions", "GET");
    },
    isAllowViewAllTransactionCase: (state, getters) => {
      // /case-transactions
      return getters.isAllowPermission("/case-transactions-all", "GET");
    },
    isAllowPrincipalsCase: (state, getters) => {
      // /case-principals
      return getters.isAllowPermission("/case-principals", "GET");
    },
    isAllowViewAllPrincipalsCase: (state, getters) => {
      // /case-principals
      return getters.isAllowPermission("/case-principals-all", "GET");
    },
    isSkipPrincipalVerify: (state, getters) => {
      // /case-principals-skip-verify
      return getters.isAllowPermission("/case-principals-skip-verify", "GET");
    },
    // /case-inquires
    isAllowViewInquiresCase: (state, getters) => {
      // /case-inquires
      return getters.isAllowPermission("/case-inquires", "GET");
    },
    isAllowViewAllInquiresCase: (state, getters) => {
      // /case-inquires-all
      return getters.isAllowPermission("/case-inquires-all", "GET");
    },
    isAllowExportExcelCase: (state, getters) => {
      return getters.isAllowPermission("/api/v1/case-exports/excel", "POST");
    },
    isAllowExportWordCase: (state, getters) => {
      return getters.isAllowPermission("/api/v1/case-exports/word", "POST");
    },
    isAllowFilterDeal: (state, getters) => {
      return getters.isAllowPermission("/api/v1/cases-filter-deal/", "GET");
    },
    isAllowFilterShelf: (state, getters) => {
      return getters.isAllowPermission("/api/v1/cases-filter-shelf/", "GET");
    },
    isAllowFilterCreator: (state, getters) => {
      return getters.isAllowPermission("/api/v1/cases-filter-creator/", "GET");
    },
    isAllowApprove: (state, getters) => {
      return getters.isAllowPermission("/api/v1/approve/", "GET");
    },
    isAllowCreateCustomer: (state, getters) => {
      return getters.isAllowPermission("/api/v1/customers/", "POST");
    },
    isAllowUpdateCustomer: (state, getters) => {
      return getters.isAllowPermission("/api/v1/customers/{customer_id}", "PUT");
    },
  },
  mutations: {
    setToken(state, data) {
      state.token = data.access_token;
    },
    setTokenType(state, data) {
      state.token_type = data.token_type;
    },
    setSuperUser(state, data) {
      state.is_superuser = data.is_superuser;
    },
    setPermissions(state, data) {
      state.permissions = data.permissions;
    },
    setUserID(state, data) {
      state.user_id = data.user_id;
    },
    setLastName(state, data) {
      state.last_name = data.last_name;
    },
    setFirstName(state, data) {
      state.first_name = data.first_name;
    },

    setDistrictForCity(state, { city, districts }) {
      state.district_map[city] = districts;
    },
    setRegionForCityDistrict(state, { city, district, regions }) {
      const cd = city + "_" + district;
      state.region_map[cd] = regions;
    },
    reset_cache(state) {
      state.district_map = {};
      state.region_map = {};
    },
    logout(state) {
      state.token = null;
      state.token_type = null;
      state.roles = [];
      state.permissions = [];
    },
    setRoles(state, data) {
      state.roles = data;
    },
    setGroupOptions(state, data) {
      state.group_options = mapGroupOptions(data)
      state.group_ids_map = getGroupOptions(state.group_options)
      // console.log("setGroupOptions", state.group_options)
      // console.log("setGroupOptions", state.group_ids_map)
    }
  },
  actions: {
    submitToken({ commit }, data) {
      commit("setToken", data);
      commit("setTokenType", data);
      commit("setSuperUser", data);
      commit("setPermissions", data);
      commit("setUserID", data);
      commit("setLastName", data);
      commit("setFirstName", data);
    },
    setDistrict({ commit }, data) {
      commit("setDistrictForCity", data);
    },
    setRegion({ commit }, data) {
      commit("setRegionForCityDistrict", data);
    },
    reset_cache(commit) {
      commit("reset_cache");
    },
    logout({ commit }) {
      commit("logout");
    },
    setRoles({ commit }, data) {
      commit("setRoles", data);
    },
    fetchInitialData({ commit }) {
      console.log("fetchInitialData")
      const url = `${process.env.VUE_APP_SERVER_URL}/api/v1/customer_groups/`;
      axios.get(url)
        .then(response => {
          commit('setGroupOptions', response.data)
        })
        .catch(error => {
          console.error('Failed to fetch initial data:', error)
        })
    },
  },
  modules: {},
});
