<template>
  <div>
    <div @click="drawer = false" v-if="drawer" class="diy-drawer-state"></div>
    <div><h1>222</h1></div>

    <v-app style="padding-top: 20vh">
      <drawer2
        :drawer="drawer"
        :sidebarColor="sidebarColor"
        :sidebarTheme="sidebarTheme"
      >
      </drawer2>

      <v-main>
        <app-bar
          v-if="$route.name != 'Profile'"
          background="bg-transparent"
          has-bg
          @drawer-toggle="drawer = $event"
          :toggle-active="drawer"
          :navbarFixed="navbarFixed"
          @toggleSettingsDrawer="toggleSettingsDrawer"
          @updateSidebarTheme="updateSidebarTheme"
        ></app-bar>
        <app-bar
          v-else-if="$route.name == 'Profile'"
          background="bg-default"
          has-bg
          @drawer-toggle="drawer = $event"
          :toggle-active="drawer"
          @updateSidebarTheme="updateSidebarTheme"
        ></app-bar>
        <app-bar
          v-else
          background="primary"
          linkColor="rgba(0,0,0,.6)"
          @drawer-toggle="drawer = $event"
          :toggle-active="drawer"
          @updateSidebarTheme="updateSidebarTheme"
        ></app-bar>
        <fade-transition :duration="200" origin="center top" mode="out-in">
          <!-- your content here -->
          <router-view></router-view>
        </fade-transition>
        <content-footer v-if="!$route.meta.hideFooter"></content-footer>
        <v-btn
          v-if="false"
          :ripple="false"
          icon
          rounded
          color="#fff"
          width="52px"
          height="52px"
          class="
            fixed-plugin-button
            position-fixed
            btn-light
            bg-white
            text-dark
            z-index-9999
          "
          @click="showSettingsDrawer = true"
        >
          <v-icon size="20">fa fa-cog py-2</v-icon>
        </v-btn>

        <settings-drawer
          :showSettingsDrawer="showSettingsDrawer"
          @toggleSettingsDrawer="toggleSettingsDrawer"
          @updateSidebarColor="updateSidebarColor"
          @updateSidebarTheme="updateSidebarTheme"
          @toggleNavbarPosition="toggleNavbarPosition"
        >
        </settings-drawer>
      </v-main>
    </v-app>
  </div>
</template>
<script>
// /* eslint-disable no-new */
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`);
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className);
    }, 100);
  }
}

import { FadeTransition } from "vue2-transitions";
import Drawer2 from "@/components/Drawer2.vue";
import AppBar from "@/components/AppBar.vue";
import ContentFooter from "@/components/Footer.vue";
import SettingsDrawer from "@/components/Widgets/SettingsDrawer.vue";

export default {
  components: {
    ContentFooter,
    FadeTransition,
    Drawer2,
    AppBar,
    SettingsDrawer,
  },
  data() {
    return {
      drawer: null,
      showSettingsDrawer: false,
      sidebarColor: "success",
      sidebarTheme: "white",
      navbarFixed: false,
    };
  },
  beforeCreate() {
    const theme = localStorage.getItem("dark_theme");
    if (theme) {
      if (theme === "true") {
        this.$vuetify.theme.dark = true;
      } else {
        this.$vuetify.theme.dark = false;
      }
    }
  },
  methods: {
    initScrollbar() {
      let isWindows = navigator.platform.startsWith("Win");
      if (isWindows) {
        initScrollbar("sidenav");
      }
    },
    toggleSettingsDrawer(value) {
      this.showSettingsDrawer = value;
    },
    updateSidebarColor(value) {
      this.sidebarColor = value;
    },
    updateSidebarTheme(value) {
      this.sidebarTheme = value;
    },
    toggleNavbarPosition(value) {
      this.navbarFixed = value;
    },
  },
  mounted() {
    this.initScrollbar();
    const theme = this.$vuetify.theme.dark ? "dark" : "white";
    this.updateSidebarTheme(theme);
  },
};
</script>
<style lang="scss">
.diy-drawer-state {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw; /* 100% 视口宽度 */
  height: 100vh; /* 100% 视口高度 */
  right: 0;
  z-index: 9999;
}
</style>
